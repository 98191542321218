/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100%;
  overflow: hidden;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  width: 100% !important;
  height: 100% !important;
  background-color: #f09d85;
}

.embedded-wix {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .embedded-wix {
    width: 100vw;
    height: 100vh;
  }
}